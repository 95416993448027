import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { evoConfigurationUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRequestWithXSRFToken, sendRetrieveRequest } from '../../../utils';
import { EvoConfigurationState } from '../../integrations/types';

export const evoConfiguration = (
    type: 'GET' | 'POST',
    payload?: { goose: EvoConfigurationState },
): Observable<EvoConfigurationState> => {
    const url = evoConfigurationUrl();
    const obs = type === 'GET' ? sendRetrieveRequest(url) : sendRequestWithXSRFToken(url, payload);
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const config = convertToCamel<any>(response);
            return observableOf(config?.gooseConfig);
        }),
    );
};
