import { addSampleResultsRankedColumn } from 'state-domains/domain/configuration/shim/addSampleResultsRankedColumn';
import { deleteRankedColumn } from 'state-domains/domain/configuration/shim/deleteRankedColumn';
import { editRankedColumn } from 'state-domains/domain/configuration/shim/editRankedColumn';
import { recomputeSampleResults } from 'state-domains/domain/configuration/shim/recomputeSampleResults';
import { updateActualDepth } from 'state-domains/domain/configuration/shim/updateActualDepth';
import { loadAccountInformation } from 'state-domains/domain/shared/shim';

import { addConfigurationActivity } from './addConfigurationActivity';
import { addConfigurationActivityTable } from './addConfigurationActivityTable';
import { addConfigurationHeader } from './addConfigurationHeader';
import { addConfigurationHeaderFieldType } from './addConfigurationHeaderFieldType';
import { addConfigurationList } from './addConfigurationList';
import { addConfigurationProject } from './addConfigurationProject';
import { addConfigurationProjectActivity } from './addConfigurationProjectActivity';
import { addConfigurationSampleWorkflow } from './addConfigurationSampleWorkflow';
import { addConfigurationTable } from './addConfigurationTable';
import { addEditConfigurationActivityGroups } from './addEditConfigurationActivityGroups';
import { addFieldToHeaderDefaultSection } from './addFieldToHeaderDefaultSection';
import { addFileToListGroup } from './addFileToListGroup';
import { addHeaderSection } from './addHeaderSection';
import { addListFileGroup } from './addListFileGroup';
import { addTableToTableView } from './addTableToTableView';
import { assignUserToProject } from './assignUserToProject';
import { assignUserToProjectActivity } from './assignUserToProjectActivity';
import { assignUserToProjectActivityGroup } from './assignUserToProjectActivityGroup';
import { bulkImportList } from './bulkImportList';
import { bulkValidateImportList } from './bulkValidateImportList';
import { convertProjectBoundaries } from './convertProjectBoundaries';
import { createUpdateDeleteConfigurationCoordinate } from './createUpdateDeleteConfigurationCoordinate';
import { deleteColumn } from './deleteColumn';
import { deleteConfigurationActivity } from './deleteConfigurationActivity';
import { deleteConfigurationActivityGroup } from './deleteConfigurationActivityGroup';
import { deleteConfigurationActivityTable } from './deleteConfigurationActivityTable';
import { deleteConfigurationField } from './deleteConfigurationField';
import { deleteConfigurationHeader } from './deleteConfigurationHeader';
import { deleteConfigurationHeaderSection } from './deleteConfigurationHeaderSection';
import { deleteConfigurationLists } from './deleteConfigurationLists';
import { deleteConfigurationProject } from './deleteConfigurationProject';
import { deleteConfigurationProjectActivity } from './deleteConfigurationProjectActivity';
import { deleteConfigurationTableView } from './deleteConfigurationTableView';
import { deleteFieldFromHeader } from './deleteFieldFromHeader';
import { deleteListFile } from './deleteListFile';
import { deleteListRow } from './deleteListRow';
import { deleteListRows } from './deleteListRows';
import { deleteProjectThumbnail } from './deleteProjectThumbnail';
import { deleteSampleWorkflow } from './deleteSampleWorkflow';
import { deleteTableViewColumn } from './deleteTableViewColumn';
import { deleteTableViewTable } from './deleteTableViewTable';
import { deleteUserFromProject } from './deleteUserFromProject';
import { deleteUserFromProjectActivity } from './deleteUserFromProjectActivity';
import { deleteUserFromProjectActivityGroup } from './deleteUserFromProjectActivityGroup';
import { downloadListFile } from './downloadListFile';
import { evoConfiguration } from './evoConfiguration';
import { exportConfigurationLists } from './exportConfigurationLists';
import { exportConfigurationTables } from './exportConfigurationTables';
import { genericReorder } from './genericReorder';
import { gridPreview } from './gridPreview';
import { importList } from './importList';
import { importSampleCodes } from './importSampleCodes';
import { importTableSchema } from './importTableSchema';
import { linkActivityLists } from './linkActivityLists';
import { loadConfigurationActivities } from './loadConfigurationActivities';
import { loadConfigurationActivityGroups } from './loadConfigurationActivityGroups';
import { loadConfigurationCategories } from './loadConfigurationCategories';
import { loadConfigurationGrids } from './loadConfigurationGrids';
import { loadConfigurationHeaderFieldTypes } from './loadConfigurationHeaderFieldTypes';
import { loadConfigurationHeaders } from './loadConfigurationHeaders';
import { loadConfigurationLists } from './loadConfigurationLists';
import { loadConfigurationProjects } from './loadConfigurationProjects';
import { loadConfigurationSampleWorkflows } from './loadConfigurationSampleWorkflows';
import { loadConfigurationTableViews } from './loadConfigurationTables';
import { loadDefaultLabServices } from './loadDefaultLabServices';
import { loadDefaultProjections } from './loadDefaultProjections';
import { loadEmptyListFileGroups } from './loadEmptyListFileGroups';
import { loadEvoDiscovery } from './loadEvoDiscovery';
import { loadEvoOrg } from './loadEvoOrg';
import { loadEvoWorkspaces } from './loadEvoWorkspaces';
import { loadListFileGroup } from './loadListFileGroup';
import { loadPossibleSampleResultsColumns } from './loadPossibleSampleResultsColumns';
import { loadUserPermissions } from './loadUserPermissions';
import { modifyAdminPrivileges } from './modifyAdminPrivileges';
import {
    addCertificateImport,
    deleteCertificateImport,
    editCertificateImport,
} from './modifyCertificateImport';
import { modifyColumn } from './modifyColumn';
import { modifyColumnAssociations } from './modifyColumnAssociations';
import { modifyConfigurationActivity } from './modifyConfigurationActivity';
import { modifyConfigurationActivityListSpec } from './modifyConfigurationActivityListSpec';
import { modifyConfigurationCategory } from './modifyConfigurationCategory';
import { modifyConfigurationProject } from './modifyConfigurationProject';
import { modifyConfigurationProjectActivity } from './modifyConfigurationProjectActivity';
import { modifySampleWorkflows } from './modifyConfigurationSampleWorkflows';
import { modifyHeaderSection } from './modifyHeaderSection';
import { modifyProjectActivityUserPermission } from './modifyProjectActivityUserPermissions';
import { removeUserFromProjectActivity } from './removeUserFromProjectActivity';
import { removeUserFromProjectActivityGroup } from './removeUserFromProjectActivityGroup';
import { reorderActivityTables } from './reorderActivityTables';
import { reorderColumns } from './reorderColumns';
import { reorderHeaderFields } from './reorderHeaderFields';
import { reorderProjectActivities } from './reorderProjectActivities';
import { reorderTables } from './reorderTables';
import { saveHeader } from './saveHeader';
import { saveHeaderField } from './saveHeaderField';
import { saveList } from './saveList';
import { saveListRow } from './saveListRow';
import { saveTable } from './saveTable';
import { saveWorkflow } from './saveWorkflow';
import { swapHeaderSections } from './swapHeaderSections';
import { uploadProjectThumbnail } from './uploadProjectThumbnail';
import { validateCell } from './validateCell';

export const configurationShim = {
    loadAccountInformation,
    loadConfigurationHeaderFieldTypes,
    addConfigurationHeaderFieldType,
    addConfigurationHeader,
    addHeaderSection,
    addTableToTableView,
    bulkImportList,
    bulkValidateImportList,
    deleteConfigurationHeader,
    deleteConfigurationHeaderSection,
    loadConfigurationLists,
    loadConfigurationTableViews,
    loadConfigurationHeaders,
    deleteConfigurationLists,
    deleteConfigurationTableView,
    deleteFieldFromHeader,
    deleteListRow,
    deleteListRows,
    deleteTableViewColumn,
    reorderActivityTables,
    reorderColumns,
    reorderHeaderFields,
    reorderTables,
    saveList,
    saveTable,
    saveHeader,
    saveHeaderField,
    saveListRow,
    validateCell,
    addConfigurationList,
    modifyColumn,
    modifyColumnAssociations,
    modifyHeaderSection,
    addConfigurationTable,
    deleteColumn,
    deleteTableViewTable,
    addFieldToHeaderDefaultSection,
    swapHeaderSections,
    deleteConfigurationField,
    importList,
    exportConfigurationLists,
    importTableSchema,
    exportConfigurationTables,
    loadConfigurationActivities,
    loadConfigurationGrids,
    deleteConfigurationActivity,
    deleteConfigurationActivityTable,
    addConfigurationActivity,
    addConfigurationActivityTable,
    modifyConfigurationActivity,
    linkActivityLists,
    modifyConfigurationActivityListSpec,
    loadPossibleSampleResultsColumns,
    addSampleResultsRankedColumn,
    modifySampleWorkflows,
    editRankedColumn,
    recomputeSampleResults,
    updateActualDepth,
    deleteRankedColumn,
    loadConfigurationProjects,
    addConfigurationProject,
    deleteConfigurationProject,
    modifyConfigurationProject,
    convertProjectBoundaries,
    addConfigurationProjectActivity,
    deleteConfigurationProjectActivity,
    modifyConfigurationProjectActivity,
    deleteUserFromProjectActivity,
    assignUserToProjectActivity,
    assignUserToProject,
    deleteUserFromProject,
    assignUserToProjectActivityGroup,
    deleteUserFromProjectActivityGroup,
    removeUserFromProjectActivityGroup,
    removeUserFromProjectActivity,
    uploadProjectThumbnail,
    deleteProjectThumbnail,
    loadUserPermissions,
    modifyProjectActivityUserPermission,
    loadListFileGroup,
    addListFileGroup,
    addFileToListGroup,
    deleteListFile,
    downloadListFile,
    loadEmptyListFileGroups,
    loadConfigurationSampleWorkflows,
    addCertificateImport,
    editCertificateImport,
    deleteCertificateImport,
    addConfigurationSampleWorkflow,
    saveWorkflow,
    deleteSampleWorkflow,
    loadDefaultLabServices,
    importSampleCodes,
    reorderProjectActivities,
    loadConfigurationCategories,
    gridPreview,
    createUpdateDeleteConfigurationCoordinate,
    loadDefaultProjections,
    modifyAdminPrivileges,
    modifyConfigurationCategory,
    loadConfigurationActivityGroups,
    deleteConfigurationActivityGroup,
    addEditConfigurationActivityGroups,
    loadEvoWorkspaces,
    loadEvoOrg,
    evoConfiguration,
    loadEvoDiscovery,
    genericReorder,
};
