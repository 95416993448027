import { NotificationType } from '@local/web-design-system';
import { Dispatch } from 'redux';

import { CLIENT_SIDE_PAGINATION_LIMIT } from 'state-domains/constants';

import {
    ADD_SNACKBAR_NOTIFICATION,
    CLEAR_JOB_LIST,
    LIST_JOBS,
    SET_JOB_SEARCH_TERM,
} from '../../../types/actionTypes';
import { typeComplete, typeFail, typePending } from '../../../utils';
import { jobShim } from '../shim';
import { Job } from '../types';

const loadJobList =
    (statuses = ['all']) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(LIST_JOBS) });
        jobShim.loadJobList(statuses).subscribe({
            next: (jobsList: Job[]) =>
                dispatch({ type: typeComplete(LIST_JOBS), payload: jobsList }),
            error: (error: Error) => {
                dispatch({ type: typeFail(LIST_JOBS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearJobList = () => (dispatch: Dispatch) => dispatch({ type: typeComplete(CLEAR_JOB_LIST) });

const setJobSearchTerm =
    (searchTerm: string, offset = 0, limit: number = CLIENT_SIDE_PAGINATION_LIMIT) =>
    (dispatch: Dispatch) =>
        dispatch({
            type: typeComplete(SET_JOB_SEARCH_TERM),
            payload: { searchTerm, offset, limit },
        });

export const actions = {
    loadJobList,
    clearJobList,
    setJobSearchTerm,
};
