import { sortBy } from 'lodash-es';
import store from 'store';

import { DEFAULT_PREFERENCES, Preferences } from 'src/utilities/types';

export const isPrintableKey = (key: string) => key.length === 1;

export type GenericObject = { [key: string]: any };
export function assign(obj: GenericObject, keyPath: string[], value: any) {
    const lastKeyIndex = keyPath.length - 1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < lastKeyIndex; ++i) {
        const key = keyPath[i];
        if (!(key in obj)) {
            // eslint-disable-next-line no-param-reassign
            obj[key] = {};
        }
        // eslint-disable-next-line no-param-reassign
        obj = obj[key];
    }
    // eslint-disable-next-line no-param-reassign
    obj[keyPath[lastKeyIndex]] = value;
}

export function arraysSymDiff<T>(a: T[], b: T[]): T[] {
    // Yields values that are only in a or b
    return a.filter((x) => !b.includes(x)).concat(b.filter((x) => !a.includes(x)));
}

export function arraysDiff<T>(a: T[], b: T[]): T[] {
    // Will only return values from a that are not in b
    return a.filter((x) => !b.includes(x));
}

export function arraysIntersection<T>(a: T[], b: T[]): T[] {
    return a.filter((x) => b.includes(x));
}

export const getPreferences = (currentUserId: string): Preferences => {
    const preferencesKey = `preferences_${currentUserId}`;
    return store?.get(preferencesKey, DEFAULT_PREFERENCES);
};

export const setStorePreferences = (currentUserId: string, value: Preferences) => {
    const preferencesKey = `preferences_${currentUserId}`;
    store.set(preferencesKey, value);
};

export const sortCollectionByName = <T extends { name: string }>(collection: T[]) =>
    sortBy(collection, (item) => item.name.toLowerCase());
